import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import countries from 'i18n-iso-countries'
import Cookies from 'js-cookie'
import {
	isValidPhoneNumber
} from 'libphonenumber-js'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import '../../assets/scss/phone-input.css'
import { IRoute } from '../../interfaces/route.interface'
import { AddressErrors, AddressTranslations } from '../../translations/translations.enum'
import { CartDivider } from '../cart/cart-divider'
import { BlackButton } from '../product/black-button'
import {
	RequestManager,
	isRequestSuccessfull,
} from '../utils'
import { routeMapping } from '../utils/route-mapping'

countries.registerLocale(
	require('i18n-iso-countries/langs/en.json')
)
countries.registerLocale(
	require('i18n-iso-countries/langs/es.json')
)

export interface IAddressFormProps
	extends IRoute {
	setIsCreatingNewAddress?: (
		isCreatingNewAddress: boolean
	) => void
	isAccountSettings?: boolean
	editingAddress?: string
	setEditingAddress?: (
		address: string | undefined
	) => void
}

const AddressForm = (
	props: IAddressFormProps
) => {
	const { t } = useTranslation()
	const { language, addressName } = useParams()
	const navigate = useNavigate()
	const [countriesSorted, setCountriesSorted] =
		useState<string[][]>([])
	const [formData, setFormData] = useState<any>({
		firstName: '',
		lastName: '',
		country: '',
		streetAndNumber: '',
		name: '',
		type: 'shipping',
		city: '',
		province: '',
		postalCode: '',
		extraInfo: '',
		phoneNumber: '',
	})
	const [errors, setErrors] = useState<any>({})

	const validateForm = () => {
		let tempErrors: any = {}

		if (!formData.name.length) {
			tempErrors.name =
				t(AddressErrors.ERROR_ADDRESS_NAME)
		}

		if (!formData.firstName.length) {
			tempErrors.firstName =
				t(AddressErrors.ERROR_ADDRESS_FIRST_NAME)
		}

		if (!formData.lastName.length) {
			tempErrors.lastName =
				t(AddressErrors.ERROR_ADDRESS_LAST_NAME)
		}

		if (!formData.country.length) {
			tempErrors.country =
				t(AddressErrors.ERROR_ADDRESS_COUNTRY)
		}

		if (!formData.city.length) {
			tempErrors.city =
				t(AddressErrors.ERROR_ADDRESS_CITY)
		}

		if (!formData.province.length) {
			tempErrors.province =
				t(AddressErrors.ERROR_ADDRESS_PROVINCE)
		}

		if (!formData.postalCode.length) {
			tempErrors.postalCode =
				t(AddressErrors.ERROR_ADDRESS_POSTAL_CODE)
		}

		if (!formData.streetAndNumber.length) {
			tempErrors.streetAndNumber =
				t(AddressErrors.ERROR_ADDRESS_PHONE_NUMBER)
		}

		if (!isValidPhoneNumber(formData.phoneNumber)) {
			tempErrors.phoneNumber =
				t(AddressErrors.ERROR_ADDRESS_PHONE_NUMBER)
		}

		setErrors(tempErrors)
		return Object.keys(tempErrors).length === 0
	}

	const handleSubmit = (event: any) => {
		event.preventDefault()
		if (validateForm()) {
			createAddress()
		}
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } =
			event.target

		setFormData({
			...formData,
			[name]:
				type === 'checkbox' ? checked : value,
		})
	}

	const handleChangePhoneNumber = (phoneNumber: any) => {
		setFormData({
			...formData,
			phoneNumber: phoneNumber
		})
	}

	useEffect(() => {
		const contriesUnsorted = Object.entries(
			countries.getNames(language || 'en')
		)
		setCountriesSorted(
			contriesUnsorted
				.sort(([keyA, valueA], [keyB, valueB]) =>
					valueA.localeCompare(valueB, 'es', {
						sensitivity: 'base',
					})
				)
				.filter(([key, value]) =>
					['ES', 'PT', 'AT', 'DE'].includes(key)
				)
		)

		if (addressName || props.editingAddress) {
			fetchAddress(
				props.editingAddress || addressName
			)
		}
	}, [language, addressName])

	const fetchAddress = async (
		addressName?: string
	) => {
		const response = await RequestManager(
			'GET',
			`user/address/${addressName}`
		)

		setFormData(response.data)
	}

	const createAddress = async () => {
		const accessToken = Cookies.get('accessToken')

		if (!accessToken) {
			window.location.href = '/login'
		}

		const response = await RequestManager(
			'POST',
			'user/address',
			true,
			formData
		)

		if (isRequestSuccessfull(response.status)) {
			if (props?.setIsCreatingNewAddress) {
				props?.setIsCreatingNewAddress(false)
				props.setEditingAddress?.(undefined)
				window.scrollTo(0, 0)
			} else if (props.isAccountSettings) {
				navigate(`/${language}/${routeMapping.accountAddresses[language || 'es']}`)
			}
		} else {
			throw new Error('Error creating address')
		}
	}

	return (
		<Container
			component='main'
			maxWidth='sm'
			sx={{
				padding: '5% 2%',
				width: '90%',
				fontFamily: 'Bembo',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				sx={{
					mb: '2rem',
					fontSize: '2rem',
				}}
			>
				{addressName
					? t(AddressTranslations.EDIT_ADDRESS)
					: props.isAccountSettings
					? t(AddressTranslations.ADD_ADDRESS)
					: t(
							AddressTranslations.DELIVERY_ADDRESS
					  )}
			</Typography>
			<CartDivider />
			<form
				onSubmit={handleSubmit}
				noValidate
			>
				<Grid
					container
					spacing={2}
					mt={'4%'}
				>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='name'
							label={t(
								AddressTranslations.ADDRESS_NAME
							)}
							name='name'
							// placeholder='Home, Work, etc.'
							value={formData.name}
							onChange={handleChange}
							error={!!errors.name}
							helperText={errors.name}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							required
							fullWidth
							id='firstName'
							label={t(
								AddressTranslations.ADDRESS_FIRST_NAME
							)}
							name='firstName'
							autoComplete='fname'
							value={formData.firstName}
							onChange={handleChange}
							error={!!errors.firstName}
							helperText={errors.name}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							required
							fullWidth
							id='lastName'
							label={t(
								AddressTranslations.ADDRESS_LAST_NAME
							)}
							name='lastName'
							autoComplete='lname'
							value={formData.lastName}
							onChange={handleChange}
							error={!!errors.lastName}
							helperText={errors.lastName}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<FormControl fullWidth>
							<InputLabel id='country-label'>
								{t(AddressTranslations.ADDRESS_COUNTRY)} *
							</InputLabel>
							<Select
								labelId='country-label'
								id='country'
								name='country'
								value={formData.country}
								label={t(AddressTranslations.ADDRESS_COUNTRY)}
								onChange={handleChange}
								error={!!errors.country}
							>
							{countriesSorted.map(
								([isoCode, countryName]) => (
									<MenuItem
										key={isoCode}
										value={isoCode}
									>
										{countryName}
									</MenuItem>
								)
							)}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='city'
							label={t(
								AddressTranslations.ADDRESS_CITY
							)}
							name='city'
							value={formData.city}
							onChange={handleChange}
							error={!!errors.city}
							helperText={errors.city}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='province'
							label={t(
								AddressTranslations.ADDRESS_PROVINCE
							)}
							name='province'
							value={formData.province}
							onChange={handleChange}
							error={!!errors.province}
							helperText={errors.province}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='postalCode'
							label={t(
								AddressTranslations.ADDRESS_POSTAL_CODE
							)}
							name='postalCode'
							value={formData.postalCode}
							onChange={handleChange}
							error={!!errors.postalCode}
							helperText={errors.postalCode}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							id='streetAndNumber'
							label={t(
								AddressTranslations.ADDRESS_STREET_AND_NUMBER
							)}
							name='streetAndNumber'
							value={formData.streetAndNumber}
							onChange={handleChange}
							error={!!errors.streetAndNumber}
							helperText={errors.streetAndNumber}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							fullWidth
							id='extraInfo'
							label={t(
								AddressTranslations.ADDRESS_ADDITIONAL_DATA
							)}
							name='extraInfo'
							value={formData.extraInfo}
							onChange={handleChange}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<PhoneInput
							value={formData.phoneNumber}
							placeholder={t(
								AddressTranslations.ADDRESS_PHONE_NUMBER
							) + ' *'}
							onChange={ handleChangePhoneNumber }
							defaultCountry="ES"
						/>
						{errors.phoneNumber && (
							<FormHelperText error>
								{errors.phoneNumber}
							</FormHelperText>
						)}
					</Grid>
					<Grid
						item
						xs={12}
					>
						<BlackButton
							text={t(
								AddressTranslations.SAVE_ADDRESS
							)}
						/>
					</Grid>
				</Grid>
			</form>
		</Container>
	)
}

export default AddressForm
